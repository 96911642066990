import 'regenerator-runtime/runtime';
import 'style/style';
import './empty'

//react
import { App } from 'components';
import { createElement } from 'react';
import { render } from 'react-dom';

const container = document.querySelector('.app');
const app = createElement(App);
if (container) {
    render(app, container);
}else{
    console.error('Container with class .app not found');
}

//see development.js
module.hot && module.hot.accept('components', Boolean);
module.hot && module.hot.accept('style/style', Boolean);
