// this file is deliberately empty

document.addEventListener('DOMContentLoaded', () => {

    const tabsElement = document.querySelector('.tabs');
    if (tabsElement) {
        tabsElement.classList.remove('loading');
    }

    const groupElement = document.querySelector('.tabs .wp-block-group');

    if (groupElement) {
        groupElement.classList.add('visible');
    }

    document.addEventListener('click', (event) => {
        const target = event.target;
    
      
        if (target.classList.contains('wp-block-button__link') && target.getAttribute('href')) {
            event.preventDefault();
            
            const href = target.getAttribute('href').replace('#', ''); 
            console.log(href)
            
            const groups = document.querySelectorAll('.wp-block-group');
            groups.forEach(group => {
                group.classList.remove('visible');
            });
    
            const targetElement = document.getElementById(href);
            if (targetElement) {
                targetElement.classList.add('visible');
                targetElement.scrollIntoView({
                    behavior: 'smooth', 
                    block: 'nearest'    
                });
            }
        }
    });
    

});
